<script>
  import Icons from "@/components/reuseables/Icons.vue";
import { mapGetters } from 'vuex';
  export default {
    name: 'UpdateUser',
    components: {
      Icons
    },
    data() {
      return {
        imageUrl: null,
        dobDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dobMenu: false,
        epmtDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        epmtMenu: false,
      }
    },

    methods: {
      handleImageUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      handleShowComponent() {
        this.showComponent = false
        this.$nextTick(() => {
          this.showComponent = true
        })
      },
    },
    computed:{
      ...mapGetters('products', ['selectedUser'])
    },
    created(){
      console.log(this.selectedUser, "user data")
    }
  }
</script>

<template>
  <div class="container">

    <div class="tw-flex sec1">
      <v-col>
        <p class="header">Profile Image</p>
        <validation-provider name="PostImage" rules="" v-slot="{ classes, errors }">
          <div class=" image-body">
            <input type="file" class="image-input" id="file" @change="handleImageUpload" />
            <label class="image-label  mt-5" for="file">
              <img v-if="imageUrl" :src="imageUrl" alt="" class="image" />
              <img v-else src="@/assets/ion_image-outline.png" alt="" class="image-icon">
            </label>
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>
      </v-col>


      <v-col class=" documents-sec">
        <p class="header mb-5">Documents</p>

        <v-file-input dense outlined placeholder="CAC_GIG" hide-details class="file-input" prepend-icon=''>
          <template #prepend-inner>
            <icons name="file-input-icon" class="mt-1  mr-2 file-icon" />
          </template>
        </v-file-input>

        <v-file-input dense outlined placeholder="SLA_GIG" hide-details class="file-input" prepend-icon=''>
          <template #prepend-inner>
            <icons name="file-input-icon" class="mt-1  mr-2 file-icon" />
          </template>
        </v-file-input>


        <v-file-input dense outlined placeholder="Onboarding_GIG" hide-details class="file-input" prepend-icon=''>
          <template #prepend-inner>
            <icons name="file-input-icon" class="mt-1  mr-2 file-icon" />
          </template>
        </v-file-input>

        <v-file-input dense outlined placeholder="POS_Collection_GIG" hide-details class="file-input" prepend-icon=''>
          <template #prepend-inner>
            <icons name="file-input-icon" class="mt-1  mr-2 file-icon" />
          </template>
        </v-file-input>

        <input type="file" class="doc-file" id="doc-file" />
        <label class="file-label" for="file">
          <icons name="input-file" width="20px" class="file-icon tw-ml-28" id="file-icon-2" />
        </label>
      </v-col>
    </div>


    <div class="tw-flex tw-flex-wrap tw-mt-5">
      <v-col md="6" cols="12">
        <label class="text-headings">First Name</label>
        <v-text-field class="mt-4 file-input" placeholder="Zoe" hide-details outlined dense></v-text-field>
      </v-col>

      <v-col md="6" cols="12">
        <label class="text-headings">Last Name</label>
        <v-text-field class="mt-4 file-input" placeholder="Davids" hide-details outlined dense></v-text-field>
      </v-col>


      <v-col md="6" cols="12">
        <label class="text-headings">Email</label>

        <v-file-input dense outlined placeholder="Create Email" hide-details class="file-input" prepend-icon=''>
          <template #prepend-inner>
            <icons name="create-icon" class="mt-1  mr-2 file-icon" />
          </template>
        </v-file-input>
      </v-col>
      <v-col md="6" cols="12">
        <label class="text-headings">Staff ID</label>

        <v-file-input dense outlined placeholder="Create Staff ID" hide-details class="file-input" prepend-icon=''>
          <template #prepend-inner>
            <icons name="create-icon" class="mt-1  mr-2 file-icon" />
          </template>
        </v-file-input>
      </v-col>


      <v-col md="6" cols="12">
        <label class="text-headings">Phone Number</label>
        <v-text-field class="mt-4 file-input" placeholder="+2340985000" hide-details outlined dense></v-text-field>
      </v-col>


      <v-col md="6" cols="12">
        <label class="text-headings">NIN</label>
        <v-text-field class="mt-4 file-input" placeholder="090000000000" hide-details outlined dense></v-text-field>
      </v-col>


      <v-col cols="12" md="6">
        <label class="text-headings">Date of Birth</label>
        <v-menu ref="epmtMenu" v-model="epmtMenu" :close-on-content-click="false" :return-value.sync="epmtDate"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="epmtDate" outlined readonly v-bind="attrs" v-on="on" hide-details color="#004aad"
              class="mt-5 file-input">
              <template #prepend-inner>
                <icons name="calender-icon" class="calender-icon" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="epmtDate" no-title scrollable color="#004aad">
            <v-spacer></v-spacer>
            <v-btn text color="#004aad" @click="epmtMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="#004aad" @click="$refs.epmtMenu.save(epmtDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>



      <v-col cols="12" md="6">
        <label class="text-headings ">Employment Date</label>
        <v-menu ref="epmtMenu" v-model="epmtMenu" :close-on-content-click="false" :return-value.sync="epmtDate"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="epmtDate" outlined readonly v-bind="attrs" v-on="on" hide-details color="#004aad"
              class="mt-5 file-input">
              <template #prepend-inner>
                <icons name="calender-icon" class="calender-icon" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="epmtDate" no-title scrollable color="#004aad">
            <v-spacer></v-spacer>
            <v-btn text color="#004aad" @click="epmtMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="#004aad" @click="$refs.epmtMenu.save(epmtDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

    </div>


  </div>


</template>

<style scoped lang="scss">
  .container {
    width: 609px;
    height: fit-content;
    background: #FEFFFF;

    @media screen and (max-width: 375px) {
      width: 315px;
    }
  }


  .image-label .image[src] {
    max-width: 100%;
    display: block;
    margin: auto;
    object-fit: cover;
    object-position: top;
  }

  .image-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 222px;
    height: 235px;
    border-radius: 10px;
    border: 1px dashed #004aad;

    @media screen and (max-width: 768px) {
      width: 200px;
      margin-right: 40px;
    }

    @media screen and (max-width: 375px) {
      width: 261px;
    }
  }

  .image-label .image {
    width: 100%;
    height: 232px;
    border-radius: 10px;
  }

  .image-input {
    display: none;
  }

  .file-input {
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 30px;
    cursor: pointer;
  }

  .file-icon {
    cursor: pointer;
  }

  .calender-icon {
    cursor: pointer;
  }

  .doc {
    display: flex;
  }

  .doc-file {
    display: none;
  }

  .file-label {
    display: flex;
    width: 240px;
    height: 36px;
    border-radius: 10px;
    border: 2px dashed #BDBDBD;
    padding-bottom: 30px;
    padding-top: 7px;
    background: linear-gradient(0deg, rgba(189, 189, 189, 0.1), rgba(189, 189, 189, 0.05));

    @media screen and (max-width: 375px) {
      width: 235px;
    }
  }

  .image-icon {
    cursor: pointer;
  }

  .file-icon {
    cursor: pointer;
  }

  .header {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #4F4F4F;
  }

  .sec1 {
    @media screen and (max-width: 375px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>